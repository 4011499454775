import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card, Carousel } from 'react-bootstrap';
import { FaPrint, FaWifi, FaHome, FaList, FaMoneyBillAlt, FaEdit, FaSignOutAlt, FaCopy, FaMobileAlt, FaBars, FaTv, FaUser, FaBolt, FaGraduationCap, FaSms, FaHistory, FaBook, FaWhatsapp, FaCode, FaCalculator } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import Loading from '../components/Loading';
import { useAuth } from '../utils/auth';
import axios from 'axios';
import Swal from 'sweetalert2';
import './Dashboard.css';
import { generateRandomString } from './utils';

function Dashboard() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [adminLink, setAdminLink] = useState('');
  const [siteBonus, setSiteBonus] = useState('');
  const [pricingData, setPricingData] = useState([]);
  const [electricCharge, setElectricCharge] = useState(null);
  const [Monnify, setMonnify] = useState(null);
  const [monnifyApiKey, setmonnifyApiKey] = useState(null);
  const [monnifyContractCode, setmonnifyContractCode] = useState(null);

  const [referralBonusAmount, setreferralBonusAmount] = useState(null);
  const [domain, setDomain] = useState('');
  const [verificationMethod, setVerificationMethod] = useState('BVN');
  const [verificationValue, setVerificationValue] = useState('');
  const [isVerificationSubmitting, setIsVerificationSubmitting] = useState(false); 
  const [textColor, settextColor] = useState(null);
  const [backgroundColor, setbackgroundColor] = useState(null);
  const [whatsappNumber, setwhatsappNumber] = useState(null);
  const [advice, setAdvice] = useState('');
  const [popUpMessage, setpopUpMessage] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false); 
  

  const [siteName, setSiteName] = useState('');
  const [websiteSlogan, setwebsiteSlogan] = useState('');
  const [siteLogo, setsiteLogo] = useState('');
  const [manifestData, setManifestData] = useState(null);


    const [siteEmail, setsiteEmail] = useState('');
  const [sitePhone, setsitePhone] = useState('');
  const [aboutUs, setaboutUs] = useState('');
  const [heroImage, setheroImage] = useState('');
  const [aboutUsImage, setaboutUsImage] = useState('');
  const [dashboardPagePriceDisplay, setdashboardPagePriceDisplay] = useState('');
  const [aboutImage, setaboutImage] = useState('');
  const [homePagePriceDisplay, sethomePagePriceDisplaye] = useState('');
  const [deferredPrompt, setDeferredPrompt] = useState(null);


  useAuth(); 

  useEffect(() => {
    const hostname = window.location.hostname;
    if (hostname.startsWith('www.')) {
      const newHost = hostname.replace('www.', '');
      window.location.replace(`https://${newHost}`); 
    }
  }, []);




  const cardStyle = {
    border: 'none',
    borderRadius: '9px',
    padding: '20px',
    marginBottom: '20px',
    backgroundColor: '#fff'
  };

  const cardTitleStyle = {
    fontSize: '1.25rem',
    marginBottom: '10px'
  };

  const cardTextStyle = {
    fontSize: '1rem',
    marginBottom: '15px'
  };

  const hrStyle = {
    margin: '20px 0'
  };







  const createBankAccount = async () => {
    const token = localStorage.getItem('userToken');
    const domain = window.location.hostname;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/autoBankCreation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ token, domain }),
      });

      if (!response.ok) {
        throw new Error('Failed to create Dedicated bank account');
      }

      const responseData = await response.json();
      setData({ ...data, bankAccounts: [responseData.bankAccount] });

    } catch (error) {
      console.error('Error creating bank account:', error);
    }
  };



  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (!token) {
      navigate('/');
    } else {
      validateToken(token);
      fetchElectricCharge();
    }
  }, [navigate]);

  const handleSignOut = () => {
    localStorage.clear();
    navigate('/');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuLinkClick = (path) => {
    setIsMenuOpen(false); // Close menu after clicking a link
    navigate(path);
  };

  const fetchElectricCharge = async () => {
    const domain = window.location.hostname;
    const url = `${process.env.REACT_APP_BACKEND_URL}/site/info`;

    try {
      const response = await axios.post(url, {}, { // Empty object for body
        headers: {
          'Content-Type': 'application/json',
          'Domain': domain 
        }
      });

      if (response.data.success) {
        setElectricCharge(response.data.electricCharge);
        setreferralBonusAmount(response.data.referralBonusAmount);
        settextColor(response.data.textColor);
        setbackgroundColor(response.data.dashboardBG);
        setwhatsappNumber(response.data.whatsappNumber);
        setpopUpMessage(response.data.popUpMessage);
        setPricingData(response.data.pricing);
        setDomain(domain);
        setaboutImage(response.data.aboutImage);
        setSiteName(response.data.siteName);
        setwebsiteSlogan(response.data.websiteSlogan);
        setsiteLogo(response.data.siteLogo);      
        setaboutUs(response.data.aboutUs);
        setheroImage(response.data.heroImage);
        setaboutUsImage(response.data.aboutImage);
        setdashboardPagePriceDisplay(response.data.dashboardPagePriceDisplay);
        setManifestData(response.data.manifest);
        setMonnify(response.data.monnify); 
        setmonnifyApiKey(response.data.monnifyApiKey); 
        setmonnifyContractCode(response.data.monnifyContractCode); 
      }
    } catch (error) {
      console.error('Error fetching site info:', error);
    }
  };











  useEffect(() => {
    if ('beforeinstallprompt' in window) {
      window.addEventListener('beforeinstallprompt', (event) => {
        event.preventDefault(); 
        setDeferredPrompt(event); 
      });
    }
    
    return () => {
      if ('beforeinstallprompt' in window) {
        window.removeEventListener('beforeinstallprompt', () => {});
      }
    };
  }, []);

  useEffect(() => {
    if (deferredPrompt && !window.matchMedia('(display-mode: standalone)').matches) {
      Swal.fire({
        title: 'Install Our App',
        text: 'Do you want to install our app for a smoother experience?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Install',
        cancelButtonText: 'Later'
      }).then((result) => {
        if (result.isConfirmed) {
          deferredPrompt.prompt();
          deferredPrompt.userChoice.then((choiceResult) => {
            setDeferredPrompt(null); 
          });
        }
      });
    }
  }, [deferredPrompt]);

  const [showModal, setShowModal] = useState(false); 
  const handleGenerateAccount = () => {
    setShowModal(true); // Open the modal
  };





  function MetaTags() {
 
        return (
      <Helmet>
        <title>{siteName} - {websiteSlogan}</title>
        <meta name="description" content={aboutUs} />
        <meta property="og:title" content={`${siteName} - ${websiteSlogan}`} />
        <meta property="og:description" content={aboutUs} />
        <meta property="og:image" content={heroImage} />
        <meta property="og:url" content={`https://${window.location.hostname}`} /> 
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={siteName} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${siteName} - ${websiteSlogan}`} />
        <meta name="twitter:description" content={aboutUs} />
        <meta name="twitter:image" content={heroImage} />
        <meta name="twitter:url" content={`https://${window.location.hostname}`} /> 
      </Helmet>
    );
  }

















  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.adviceslip.com/advice');
        const responseData = await response.json();

        if (responseData && responseData.slip && responseData.slip.advice) {
          setAdvice(responseData.slip.advice);
        }
      } catch (error) {
        console.error('Error fetching advice:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once after the initial render

  const handleAirtimeConversion = async () => {
    const backgroundColor = 'ff4b5c'; // Define your background color here

    const { value: airtimeAmount } = await Swal.fire({
      title: 'Enter Airtime Amount',
      input: 'number',
      inputLabel: 'Airtime Amount',
      inputPlaceholder: 'Enter the amount of airtime to sell',
      inputAttributes: {
        min: 0,
      },
      showCancelButton: true,
      confirmButtonText: 'Submit',
      cancelButtonText: 'Cancel',
      customClass: {
        confirmButton: 'swal2-confirm-custom'
      },
      inputValidator: (value) => {
        if (!value || isNaN(value) || value <= 0) {
          return 'Please enter a valid amount';
        }
      },
      didOpen: () => {
        const confirmButton = Swal.getConfirmButton();
        confirmButton.style.backgroundColor = `${backgroundColor}`;
      }
    });

    if (airtimeAmount) {
      const buyingAmount = (airtimeAmount * 0.9).toFixed(2);

      const result = await Swal.fire({
        title: 'Confirm Conversion',
        html: `
          <p>Amount to sell: <b>₦${airtimeAmount}</b></p>
          <p>Amount we are buying: <b>₦${buyingAmount}</b></p>
        `,
        showCancelButton: true,
        confirmButtonText: 'Agree',
        cancelButtonText: 'Disagree',
        customClass: {
          confirmButton: 'swal2-confirm-custom'
        },
        didOpen: () => {
          const confirmButton = Swal.getConfirmButton();
          confirmButton.style.backgroundColor = `${backgroundColor}`;
        }
      });


    }
  };

  

  const handleCopy = (accountNumber) => {
    if (accountNumber) {
      navigator.clipboard.writeText(accountNumber).then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Copied!',
          text: 'Account number has been copied to the clipboard.',
          timer: 2000,
          showConfirmButton: false,
        });
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to copy the account number.',
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  useEffect(() => {
    const createBankAccount = async () => {
      const token = localStorage.getItem('userToken');
      const domain = window.location.hostname;

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/autoBankCreation`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ token, domain }),
        });

        if (!response.ok) {
          throw new Error('Failed to create Dedicated bank account');
        }

        const responseData = await response.json();
        setData({ ...data, bankAccounts: [responseData.bankAccount] });

      } catch (error) {
        console.error('Error creating bank account:', error);
      }
    };

    // Ensure createBankAccount is only called when needed
    if (data && data.bankAccounts && data.bankAccounts.length === 0) {
      createBankAccount();
    }
  }, [data]);

  useEffect(() => {
    let link = localStorage.getItem('adminLink');
    if (!link) {
      link = generateRandomString(20);
      localStorage.setItem('adminLink', link);
    }
    setAdminLink(link);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('userToken');

    if (!token) {
      navigate('/');
    } else {
      validateToken(token);
    }
  }, [navigate]);

  const validateToken = async (token) => {
    const domain = window.location.hostname;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/userInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Add Authorization header
        },
        body: JSON.stringify({ token, domain }),
      });

      if (!response.ok) {
        throw new Error('Failed to validate token');
      }

      const responseData = await response.json();
      if (responseData.status === 'failed') {
        localStorage.clear();
        navigate('/');
      } else {
        setData(responseData.user);
        setIsLoading(false);
      }
    } catch (error) {
      localStorage.clear();
      navigate('/');
    }
  };











  const handleVerificationSubmit = () => {
    if (verificationValue.trim() === '') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter a valid amount!',
      });
      return;
    }
  
    const amount = parseFloat(verificationValue);
    if (isNaN(amount) || amount <= 0) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Amount',
        text: 'Please enter a valid amount greater than 0.',
      });
      return;
    }
  
    
    if (window.MonnifySDK) {
      const domain = window.location.hostname;

      window.MonnifySDK.initialize({
        amount: amount,
        currency: 'NGN',
        reference: `REF-${new Date().getTime()}`,
        customerFullName: data.fullName,
        customerEmail: data.email,
        apiKey: monnifyApiKey,
        contractCode: monnifyContractCode,
        paymentDescription: 'Wallet Funding',
        metadata: {
          domain: domain
        },
        onLoadStart: () => {
          console.log('Monnify SDK loading...');
        },
        onLoadComplete: () => {
          console.log('Monnify SDK is ready.');
        },
        onComplete: (response) => {
          if (response.paymentReference) {
            Swal.fire({
              icon: 'success',
              title: 'Payment Successful',
              text: `Transaction Reference: ${response.paymentReference}`,
            }).then(() => {
              createBankAccount(); // Update the backend with the new wallet balance
              navigate(0);
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Payment Failed',
              text: 'Please try again later.',
            });
          }
        },
        onClose: () => {
          console.log('Monnify payment modal closed.');
        },
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Monnify SDK Not Loaded',
        text: 'The payment service could not be initialized. Please try again later.',
      });
    }
  };
  










  const handleOutsideClick = (e) => {
    const menuPanel = document.querySelector('.menu-panel');
    const navbar = document.querySelector('.top-nav');

    // Check if the clicked element is within the menu panel or navbar
    if (!menuPanel.contains(e.target) && !navbar.contains(e.target)) {
      setIsMenuOpen(false); // Close the menu
    }
  };

  useEffect(() => {
    // Add the click event listener
    document.addEventListener('mousedown', handleOutsideClick);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []); // Add an empty dependency array to ensure this effect runs only once 

  useEffect(() => {
    if (popUpMessage) {
      Swal.fire({
        html: popUpMessage.replace(/\r\n/g, '<br>'),
      });
    }
  }, [popUpMessage]); 

  const handleReferralClick = async () => {
    const domain = window.location.hostname;
    const referralLink = `https://${domain}?ref=${data.refCode}`;
    const token = localStorage.getItem('userToken');

    const swalResult = await Swal.fire({
      title: 'Referral Link',
      input: 'text',
      inputValue: referralLink,
      inputAttributes: {
        readonly: true,
      },
      inputClass: 'swal2-input-copy',
      showCancelButton: true,
      cancelButtonText: 'Close',
      showConfirmButton: false, // Hide the default confirm button
      html: `
        <p>You earn a ${referralBonusAmount}% bonus every time someone you refer funds their wallet. Don't wait—start sharing your referral link now!</p>
        <style>
          .swal2-confirm-custom {
            background-color: ${backgroundColor};
            color: white;
            border: none;
            padding: 10px 20px;
            margin: 5px;
            cursor: pointer;
          }
          .swal2-cancel-custom {
            background-color: #aaa;
            color: white;
            border: none;
            padding: 10px 20px;
            margin: 5px;
            cursor: pointer;
          }
        </style>
        <button id="withdrawBonus" class="swal2-confirm-custom" >Withdraw Bonus</button>
        <button id="copyLink" class="swal2-confirm-custom">Copy Link</button>
      `,
      didOpen: () => {
        document.getElementById('withdrawBonus').addEventListener('click', async () => {
          Swal.showLoading();

          try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/bonusWithdraw`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Domain': domain
              },
              body: JSON.stringify({ domain, token }),
            });
            const responseData = await response.json();

            if (!response.ok) {
              throw new Error(`${responseData.message}`);
            }
            validateToken(token);
            Swal.fire('Success!', 'Bonus withdrawn successfully.', 'success');
          } catch (error) {
            Swal.fire('Error!', `${error.message}`, 'error');
          }
        });

        document.getElementById('copyLink').addEventListener('click', () => {
          navigator.clipboard.writeText(referralLink).then(() => {
            Swal.fire('Copied!', 'Referral link has been copied to clipboard.', 'success');
          });
        });
      }
    });
  };

  const AdminhandleCardClick = () => {
    navigate(`/${adminLink}`);
  };

  const handleCardClick = (path) => {
    navigate(path);
  };

  if (isLoading) {
    return <Loading />;
  }

  const isAdmin = data && data.superUser;

  const newLocal = '#d63031';
  return (
    
    <div className="dashboard">
    <MetaTags /> 

{/* Helmet for manifest */}
<Helmet>
  {manifestData && (
    <link rel="manifest" href={`data:application/json;charset=utf-8,${encodeURIComponent(JSON.stringify(manifestData))}`} />
  )}
</Helmet>
 



      <Container className="mb-5">
        <header className="top-nav">
          <Row className="align-items-center">
            <Col md={12} className="d-flex justify-content-between align-items-center">
              <span><b>  Hello {data && data.fullName.split(' ')[0]} 🥰
              </b></span>

              <FaBars onClick={toggleMenu} style={{ cursor: 'pointer' }} />
            </Col>
          </Row>
        </header>

        {/* Menu Side Panel (slide in from right) */}
        <div className={`menu-panel ${isMenuOpen ? 'open' : ''}`}>
          <ul>
            <li onClick={toggleMenu}>
              <FaHome className="menu-icon" />
              <span>HOME</span>
            </li>
            <li onClick={() => handleMenuLinkClick('/data')}>
              <FaWifi className="menu-icon" />
              <span>BUY DATA</span>
            </li>
            <li onClick={() => handleMenuLinkClick('/airtime')}>
              <FaMobileAlt className="menu-icon" />
              <span>BUY AIRTIME</span>
            </li>
            <li onClick={() => handleMenuLinkClick('/datacard')}>
              <FaPrint className="menu-icon" />
              <span>DATA CARD</span>
            </li>
            <li onClick={() => handleMenuLinkClick('/electric')}>
              <FaBolt className="menu-icon" />
              <span>ELECTRICITY</span>
            </li>
            <li onClick={handleAirtimeConversion}>
              <FaHistory className="menu-icon" />
              <span>AIRTIME 2 CASH</span>
            </li>
            <li onClick={() => handleMenuLinkClick('/cable')}>
              <FaTv className="menu-icon" />
              <span>CABLE TV</span>
            </li>
            <li onClick={() => handleMenuLinkClick('/sms')}>
              <FaSms className="menu-icon" />
              <span>BULK SMS</span>
            </li>
            <li onClick={() => handleMenuLinkClick('/exam')}>
              <FaUser className="menu-icon" />
              <span>RESULT PIN</span>
            </li>

            <li onClick={() => handleMenuLinkClick('/histories')}>
              <FaHistory className="menu-icon" />
              <span>HISTORY</span>
            </li>
            <li onClick={() => handleMenuLinkClick('/pricing')}>
              <FaList className="menu-icon" />
              <span>PRICING</span>
            </li>
            <li onClick={() => handleMenuLinkClick('/profile')}>
              <FaEdit className="menu-icon" />
              <span>PROFILE</span>
            </li>
            <li onClick={handleSignOut}>
              <FaSignOutAlt className="menu-icon" />
              <span>Sign Out</span>
            </li>
          </ul>
        </div>

        <center>
          <AdviceComponent advice={advice} backgroundColor={backgroundColor} />
        </center> 

        <main className="main-content">
          <Row className="mb-4">
            <Col md={4}>
              <Card className="balance-card">
                <Card.Body className="d-flex align-items-center">
                  <FaMoneyBillAlt className="menu-icon" style={{ color: backgroundColor }} />
                  <div className="leftshift">
                    <h5>Wallet Balance</h5>
                    <div><b style={{ color: backgroundColor }}>₦{data && data.wallet && data.wallet.toLocaleString()}</b></div>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4}>
              <Card className="balance-card" onClick={handleReferralClick}>
                <Card.Body className="d-flex align-items-center">
                  <img src="/icons/navbar/ic_analytics.svg" alt="User Level" className="menu-icon" style={{ height: '60px' }} />
                  <div className="leftshift">
                    <h5>Referral Bonus</h5>
                    <div><b style={{ color: backgroundColor }}>₦{data && data.bonus && data.bonus.toLocaleString()}</b></div>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4}>
              <Card className="balance-card" onClick={() => handleMenuLinkClick('/profile')}>
                <Card.Body className="d-flex align-items-center">
                  <img src="/icons/glass/ic_glass_users.png" alt="User Level" className="menu-icon" style={{ height: '60px' }} />
                  <div className="leftshift">
                    <h5>User Level</h5>
                    <b> <div style={{ color: backgroundColor }}>{data && data.userType.toUpperCase()}</div></b>
                  </div>
                </Card.Body>
              </Card>
            </Col>















            <Col md={12}>
  <Card className="balance-card">
    <Card.Body>
      <div className="leftshift">
        <p>TRANSFER FUND INTO THIS ACCOUNT NUMBER & YOUR WALLET WILL BE CREDITED IMMEDIATELY</p><hr />

        {data && data.bankAccounts && data.bankAccounts.length > 0 ? (
          <Carousel
            controls={true}
            indicators={false}
            interval={null}
            nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" />}
            prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" />}
          >
       {data.bankAccounts.map((account, index) => (
  <Carousel.Item key={index}>
    <div>
      <p>
        <b style={{ color: backgroundColor }}>
          {Monnify === 1 && (
            <center>
              <button type="button" className="btn btn-success btn-md" onClick={handleGenerateAccount}>
              CLICK HERE TO FUND YOUR WALLET VIA MONNIFY
              </button>
            </center>
          )}
        </b><br />
        Acc. Number: {account?.ac_num || ''}{' '}
        <FaCopy
          style={{ cursor: 'pointer' }}
          onClick={() => handleCopy(account?.ac_num)}
        />
        <br />
        Name: {account?.ac_name || ''}
      </p>
    </div>
  </Carousel.Item>
))}

          </Carousel>
        ) : (
          <p>Generating dedicated wallet account, refresh in 2 minutes</p>
        )}
      </div>
    </Card.Body>
  </Card>
</Col>















            <style jsx>{`
              .carousel-control-next-icon,
              .carousel-control-prev-icon {
                background-color: rgba(0, 0, 0, 0.5);
                width: 30px;
                height: 30px;
                border-radius: 50%;
              }

              .carousel-control-next-icon::after,
              .carousel-control-prev-icon::after {
                content: '';
                display: inline-block;
                width: 10px;
                height: 10px;
                border-top: 2px solid white;
                border-right: 2px solid white;
              }

              .carousel-control-next-icon::after {
                transform: rotate(45deg);
                margin-left: 5px;
              }

              .carousel-control-prev-icon::after {
                transform: rotate(-135deg);
                margin-right: 5px;
              }


   .menu-panel {
  position: fixed;
  top: 0;
  right: -300px; /* Start off-screen */
  width: 200px;
  height: 100vh;
  background-color: #fff;
  z-index: 100; /* Ensure it's on top */
  padding-top: 10px; /* Adjust for the header */
  padding-buttom: 10px;
  overflow-y: scroll;
  font-size: 13px;
  /* Combine transitions into one declaration */
  transition: right 0.3s ease-in-out; /* Smooth transition */
}

.menu-panel.open {
  /* ... your existing styles ... */
  box-shadow: -10px 0 100px rgba(0, 0, 0, 0.9);
  /* Combine transitions into one declaration */
  transition: right 0.3s ease-in-out; /* Smooth transition */
}

.menu-panel .menu-icon {
  margin-right: 10px;
  font-size: 1.7em; 
  color: ${backgroundColor}; 
}

.menu-panel.open {
  left: 0; /* Slide in when open */
}

.menu-panel ul {
  list-style: none;
  padding: 0;
}

.menu-panel li {
  padding: 15px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.menu-panel li:last-child {
  border-bottom: none;
}

.menu-panel .menu-icon {
  margin-right: 10px;
}
            `}</style>
          
          
          
          
          
          </Row>

          <Row className="mb-4">
            <Col>
              <strong>Quick Links</strong>
            </Col>
          </Row>

          <Row className="menu-items">
            <Col md={3} sm={6} xs={6}>
              <Card className="menu-item" onClick={() => handleCardClick('/data')} style={{ backgroundColor: '#fff', color: `${backgroundColor}` }}>
                <Card.Body>
                  <FaWifi className="menu-icon" />
                  <Card.Title style={{ color: 'black', fontSize: '1.0em' }}>BUY DATA</Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} sm={6} xs={6}>
              <Card className="menu-item" onClick={() => handleCardClick('/airtime')} style={{ backgroundColor: 'white', color: `${backgroundColor}` }}>
                <Card.Body>
                  <FaMobileAlt className="menu-icon" />
                  <Card.Title style={{ color: 'black', fontSize: '1.0em' }}>BUY AIRTIME</Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} sm={6} xs={6}>
              <Card className="menu-item" onClick={() => handleCardClick('/datacard')} style={{ backgroundColor: 'white', color: `${backgroundColor}` }}>
                <Card.Body>
                  <FaPrint className="menu-icon" />
                  <Card.Title style={{ color: 'black', fontSize: '1.0em' }}>DATA CARD</Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} sm={6} xs={6}>
              <Card className="menu-item" onClick={() => handleCardClick('/electric')} style={{ backgroundColor: '#fff', color: `${backgroundColor}` }}>
                <Card.Body>
                  <FaBolt className="menu-icon" />
                  <Card.Title style={{  color: 'black', fontSize: '1.0em' }}>ELECTRICITY</Card.Title>
                </Card.Body>
              </Card>
            </Col> 
            <Col md={3} sm={6} xs={6}>
              <Card className="menu-item" onClick={handleAirtimeConversion} style={{ backgroundColor: '#fff', color: `${backgroundColor}` }}>
                <Card.Body>
                  <FaHistory className="menu-icon" />
                  <Card.Title style={{ color: 'black', fontSize: '1.0em' }}>AIRTIME 2 CASH</Card.Title>
                </Card.Body>
              </Card>
            </Col>

            <Col md={3} sm={6} xs={6}>
              <Card className="menu-item" onClick={() => handleCardClick('/cable')} style={{ backgroundColor: '#fff', color: `${backgroundColor}` }}>
                <Card.Body>
                  <FaTv className="menu-icon" />
                  <Card.Title style={{ color: 'black', fontSize: '1.0em' }}>CABLE TV</Card.Title>
                </Card.Body>
              </Card>
            </Col>

            <Col md={3} sm={6} xs={6}>
              <Card className="menu-item" onClick={() => handleCardClick('/sms')} style={{ backgroundColor: '#fff', color: `${backgroundColor}` }}>
                <Card.Body>
                  <FaSms className="menu-icon" />
                  <Card.Title style={{ color: 'black', fontSize: '1.0em' }}>BULK SMS</Card.Title>
                </Card.Body>
              </Card>
            </Col>

            <Col md={3} sm={6} xs={6}>
              <Card className="menu-item" onClick={() => handleCardClick('/exam')} style={{ backgroundColor: '#fff', color: `${backgroundColor}` }}>
                <Card.Body>
                  <FaUser className="menu-icon" />
                  <Card.Title style={{ color: 'black', fontSize: '1.0em' }}>RESULT PIN</Card.Title>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} sm={6} xs={6}>
              <Card className="menu-item" onClick={() => handleCardClick('/histories')} style={{ backgroundColor: '#fff', color: `${backgroundColor}` }}>
                <Card.Body>
                  <FaHistory className="menu-icon" />
                  <Card.Title style={{ color: 'black', fontSize: '1.0em' }}>HISTORY</Card.Title>
                </Card.Body>
              </Card>
            </Col>

            <Col md={12} lg={12} sm={12}>
              <Card className="menu-item2" style={{ backgroundColor: 'white', color: 'black' }} onClick={() => window.open(`https://wa.me/${whatsappNumber}?text=I'm interested in affiliate VTU website. My account name is ${data && data.fullName}, my email: ${data && data.email}`, '_blank')}>
                <Card.Body>
                  Interested in having a VTU website like this? Partner with us today and start earning daily! Click here to get started.
                </Card.Body>
              </Card>
            </Col>


            <div>
            

            {dashboardPagePriceDisplay === 1 && pricingData.length > 0 && ( // Conditional rendering 
    <div style={cardStyle}>
      <div onClick={() => handleMenuLinkClick('/pricing')}>
        <center>  <strong>{domain.toUpperCase()} PRICING</strong> </center> <hr />
        <table className="table table-responsive">
          <tbody>
            {/* Sort the pricingData by network in descending order */}
            {pricingData.sort((a, b) => {
              if (b.network < a.network) {
                return 1;
              } else if (b.network > a.network) {
                return -1;
              } else {
                return 0; 
              }
            }).map((plan, index) => (
              <tr key={index}>
                <td style={cardTextStyle} width="50%">{plan.network} {plan.planType === 'CGD' ? 'CORPORATE GIFTING' : plan.planType}</td>
                <td style={cardTextStyle}><small>Enduser: ₦{plan.endUserAmount}/GB<br />
                Agent: ₦{plan.AgentAmount}/GB<br />
                Reseller: ₦{plan.resellerAmount}/GB<br />
                Websites: ₦{plan.webOwnerAmount}/GB            
                </small>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <center>See full list</center>
      </div>
    </div>
  )}

  
</div>


  {/* Bootstrap Modal */}
  <div className={`modal fade ${showModal ? 'show' : ''}`} id="accountGenerationModal" tabIndex="-1" aria-labelledby="accountGenerationModalLabel" aria-hidden="true" style={{ display: showModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-sm" style={{backgroundColor: `${backgroundColor}` }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="accountGenerationModalLabel">Wallet Funding</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)}></button>
            </div>
            <div className="modal-body">

              <input type="text" className="form-control mt-3" placeholder="Amount" value={verificationValue} onChange={(e) => setVerificationValue(e.target.value)} />

            </div>
            <div className="modal-footer">
            <button type="button" className="btn btn-success" disabled={isVerificationSubmitting} onClick={handleVerificationSubmit}> {/* Call handleVerificationSubmit here */}
                {isVerificationSubmitting ? (
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                ) : (
                  'Continue'
                )}
              </button>
              <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => setShowModal(false)}>Close</button>

            </div>
          </div>
        </div>
      </div>


          </Row>

        </main>
      </Container>

      <Footer />

      <div style={{
        position: 'fixed',
        bottom: '10px',
        left: '10px',
        backgroundColor: '#25D366',
        borderRadius: '50%',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        animation: 'bounce 10s infinite',
      }}>
        <a href={`https://wa.me/${whatsappNumber}`} target="_blank" rel="noopener noreferrer">
          <FaWhatsapp size={35} color="#fff" />
        </a>
      </div>
      <style>
      {`
          @keyframes bounce {
            0%, 100% {
              transform: translateY(0);
              animation-timing-function: ease-in-out;
            }
            
            50% {
              transform: translateY(-20px);
              animation-timing-function: ease-in-out;
            }
          }

          .floating-icon {
            animation: bounce 4s infinite;
          }
.menu-item2{

}

        `}
      </style>
    </div>
  );
}





function AdviceComponent({ advice, backgroundColor }) {
  const [typedText, setTypedText] = useState('');
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    let timer = setTimeout(() => {
      setIsTyping(false);
    }, 1500); // Adjust delay as needed

    let intervalId = setInterval(() => {
      if (typedText.length < advice.length) {
        setTypedText(prevText => prevText + advice[prevText.length]);
      } else {
        clearInterval(intervalId); // Clear interval only when the entire advice is typed
      }
    }, 100); // Adjust typing speed as needed

    return () => {
      clearTimeout(timer);
      clearInterval(intervalId);
    };
  }, [advice]);

  return (
    <center>
      💭 <i style={{ color: backgroundColor }}>
        {isTyping ? typedText : advice}
      </i>
    </center>
  );
}

export default Dashboard;