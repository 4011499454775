import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { useAuth } from '../utils/auth';
import Footer from '../components/Footer';
import Loading from '../components/Loading';
import axios from 'axios';
import Swal from 'sweetalert2';
import Spinner from 'react-bootstrap/Spinner';

const DataPage = () => {
  const navigate = useNavigate();
  const [networkName, setNetworkName] = useState('');
  const [dataPlans, setDataPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [iucName, setIucName] = useState('');
  const [iucValid, setIucValid] = useState(false);
  const [electricCharge, setElectricCharge] = useState(null);
  const [isVerifying, setIsVerifying] = useState(false);

  useAuth();

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (!token) {
      navigate('/');
    } else {
      validateToken(token);
      loadNetworkData();
      fetchElectricCharge();

    }
  }, [navigate]);

  const handleBackClick = () => {
    navigate('/dashboard');
  };



  const fetchElectricCharge = async () => {
    const domain = window.location.hostname;
    const url = `${process.env.REACT_APP_BACKEND_URL}/site/info`;

    try {
      const response = await axios.post(url, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Domain': domain
        }
      });

      if (response.data.siteName) {
        setElectricCharge(response.data.cablePercentage);
      }
    } catch (error) {
      console.error('Error fetching site info:', error);
    }
  };

  const loadNetworkData = async () => {
    try {
      const token = localStorage.getItem('userToken');

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/cablePlan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Add Authorization header
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data plans');
      }

      const data = await response.json();
      setDataPlans(data.filteredDataPlans);

    } catch (error) {
      console.error('Error loading network data:', error);
    }
  };

  const validateToken = async (token) => {
    const domain = window.location.hostname;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/userInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Add Authorization header
        },
        body: JSON.stringify({ token, domain }),
      });

      if (!response.ok) {
        throw new Error('Failed to validate token');
      }

      const responseData = await response.json();

      if (responseData.status === 'failed') {
        localStorage.removeItem('userToken');
        navigate('/');
      } else {
        setUserData(responseData.user);
        setData(responseData.user);
        setIsLoading(false);
      }
    } catch (error) {
      localStorage.removeItem('userToken');
      navigate('/');
    }
  };


  


  const [uniqueCables, setUniqueCables] = useState([]);
  const [selectedCable, setSelectedCable] = useState('');
  const [filteredPlans, setFilteredPlans] = useState([]);
  
  useEffect(() => {
    const cables = [...new Set(dataPlans.map((plan) => plan.cable))];
    setUniqueCables(cables);
  }, [dataPlans]);
  
  useEffect(() => {
    if (selectedCable) {
      const plans = dataPlans.filter((plan) => plan.cable === selectedCable);
      setFilteredPlans(plans);
    } else {
      setFilteredPlans([]);
    }
  }, [selectedCable, dataPlans]);
  
  const handleCableChange = (e) => {
    setSelectedCable(e.target.value);
  };
  


  const handleIucVerification = async () => {
    setIsVerifying(true);
    setIucValid(false);
    setIucName('');
  
    const iuc = document.getElementById('iuc').value;
    let networkId;
  
    const network = document.querySelector('select[name="network"]').value;
  
    // Map network names to their corresponding IDs
    switch (network) {
      case 'dstv':
        networkId = 1;
        break;
      case 'tstv':
        networkId = 2;
        break;
      case 'startimes':
        networkId = 3;
        break;
      default:
        networkId = null;
        break;
    }
  
    if (!networkId) {
      setFormError('Invalid network selected');
      setIsVerifying(false);
      return;
    }
  
    try {
      const response = await fetch(`https://vtu9ja.ng/api/cable/cable-validation?iuc=${iuc}&cable=${networkId}`, {
        method: 'GET',
      });
  
      const data = await response.json();
  
      if (data.status === 'success') {
        setIucName(data.name);
        setIucValid(true);
        setFormError('');
      } else {
        setFormError('Invalid IUC');
        setIucValid(false);
        setIucName('');
      }
    } catch (error) {
      console.error('Error verifying IUC:', error);
      setFormError('Error verifying IUC');
    } finally {
      setIsVerifying(false);
    }
  };
  








  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormError('');

    if (!iucValid) {
      setFormError('Please verify IUC first.');
      return;
    }

    const form = event.target;
    const formData = new FormData(form);

    const network = formData.get('network');
    const dataPlan = formData.get('dataPlan');
    const iuc = formData.get('iuc');
    const transactionPin = formData.get('transactionPin');
    const bypassValidation = formData.get('bypassValidation');

    if (!network || !dataPlan || !iuc || !transactionPin) {
      setFormError('Please fill in all required fields.');
      return;
    }

    if (data.pin !== transactionPin) {
      Swal.fire({
        title: 'Invalid Transaction Pin',
        text: 'Forgot your transaction pin? You can set a new one on the Profile page.',
      });
      return;
    }

    setLoading(true);
    

    try {
      const token = localStorage.getItem('userToken');
      const domain = window.location.hostname;

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/buyCable`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          network,
          dataPlan,
          iuc,
          transactionPin,
          bypassValidation,
          domain,
        }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || 'Failed to place order');
      }

      if (responseData.status === 'success') {
        Swal.fire({
          icon: 'success',
          text: responseData.message,
        }).then(() => {
          refreshUserInfo();
        });
      } else {
        Swal.fire({
          icon: 'error',
          text: responseData.message,
        });
      }
    } 
    catch (error) {
      console.error('Error placing order:', error);
      Swal.fire({
        icon: 'error',
        title: 'Failed!',
        text: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const refreshUserInfo = async () => {
    const token = localStorage.getItem('userToken');
    if (!token) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/userInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, domain: window.location.hostname }),
      });

      if (!response.ok) {
        throw new Error('Failed to refresh user info');
      }

      const responseData = await response.json();

      if (responseData.status === 'success') {
        setUserData(responseData.user);
        setData(responseData.user);
      }

    } catch (error) {
      console.error('Error refreshing user info:', error);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div style={{ backgroundColor: '#F9FAFB', minHeight: '100vh' }}>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand onClick={handleBackClick} style={{ cursor: 'pointer' }}>
            {'Back'}
          </Navbar.Brand>
          <Nav className="ml-auto">
            <Nav.Link>Cable TV</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Container className="mt-4">
        <Row className="justify-content-center">
          <Col md={7}>
            <Card className="balance-card mb-4">
              <Card.Body className="d-flex align-items-center">
                <FaMoneyBillAlt className="menu-icon" />
                <div className="leftshift">
                  <h5>Wallet Balance</h5>
                  <div><b>₦{data && data.wallet && data.wallet.toLocaleString()}</b></div>
                </div>
              </Card.Body>
            </Card>

            <Card style={{ backgroundColor: 'white', borderRadius: '10px', border: 'none' }}>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
    
    

                <Form.Group controlId="formNetwork">
  <Form.Label>Cable Provider {electricCharge !== null && `(Charge ₦${electricCharge})`}</Form.Label>
  <Form.Control
    as="select"
    name="network"
    required
    onChange={handleCableChange}
  >
    <option value="">Select Provider</option>
    {uniqueCables.map((cable, index) => (
      <option key={index} value={cable}>
        {cable.toUpperCase()}
      </option>
    ))}
  </Form.Control>
</Form.Group>


<Form.Group controlId="formDataPlan">
  <Form.Label>Cable Plan</Form.Label>
  <Form.Control
    as="select"
    name="dataPlan"
    required
  >
    <option value="">Select Data Plan</option>
    {filteredPlans.map((plan) => (
      <option key={plan.id} value={plan.id}>
        {plan.planName} - ₦{plan.originalAmount}
      </option>
    ))}
  </Form.Control>
</Form.Group>


                  <Form.Group controlId="formIUC">
                    <Form.Label>IUC Number</Form.Label>
                    <Form.Control
                      type="text"
                      id="iuc"
                      name="iuc"
                      autoComplete="off"
                      required
                      onBlur={handleIucVerification}
                    />

                  </Form.Group>
                  <Form.Group controlId="formTransactionPin">
                    <Form.Label>Transaction Pin</Form.Label>
                    <Form.Control
                      type="password"
                      name="transactionPin"
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formBypassValidation">
                    <Form.Check
                      type="checkbox"
                      name="bypassValidation"
                      label="Bypass Validation"
                    />
                  </Form.Group>
                  <center><b>
                  {iucName ? (
  <Form.Text className="text-success"><br />{iucName}<br /></Form.Text>
) : null}

{formError && <Form.Text className="text-danger">{formError}<br /></Form.Text>}<br />
                
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={!iucValid || isVerifying}
                  >
                    {isVerifying ? <Spinner animation="border" size="sm" /> : 'Proceed'}
                  </Button>
                  </b>
                  </center>
                </Form>
              </Card.Body>
            </Card>



          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default DataPage;
