import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container, Row, Col, Card, Form, Button, Spinner } from 'react-bootstrap';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { useAuth } from '../utils/auth';
import Footer from '../components/Footer';
import Loading from '../components/Loading';
import axios from 'axios';
import Swal from 'sweetalert2';

const DataPage = () => {
  const navigate = useNavigate();
  const [networkName, setNetworkName] = useState('');
  const [dataPlans, setDataPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const balanceCardRef = useRef(null);
  const formRef = useRef(null);
  const [discoId, setDiscoId] = useState('');
  const [meterType, setMeterType] = useState('');
  const [meterNumber, setMeterNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [validationResponse, setValidationResponse] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [validateMeterLoading, setValidateMeterLoading] = useState(false);
  const [placeOrderLoading, setPlaceOrderLoading] = useState(false);
  const [transactionPin, setTransactionPin] = useState('');
  const [electricCharge, setElectricCharge] = useState(null);

  useAuth();

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (!token) {
      navigate('/');
    } else {
      validateToken(token);
      fetchElectricCharge();
    }
  }, [navigate]);

  const fetchElectricCharge = async () => {
    const domain = window.location.hostname;
    const url = `${process.env.REACT_APP_BACKEND_URL}/site/info`;

    try {
      const response = await axios.post(url, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Domain': domain
        }
      });

      if (response.data.siteName) {
        setElectricCharge(response.data.electricCharge);
      }
    } catch (error) {
      console.error('Error fetching site info:', error);
    }
  };

  const validateToken = async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/userInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ token, domain: window.location.hostname }),
      });

      if (!response.ok) throw new Error('Failed to validate token');

      const responseData = await response.json();
      if (responseData.status === 'failed') {
        localStorage.removeItem('userToken');
        navigate('/');
      } else {
        setData(responseData.user);
        setIsLoading(false);
      }
    } catch (error) {
      localStorage.removeItem('userToken');
      navigate('/');
    }
  };

  const handleValidateMeterNumber = async () => {
    setValidateMeterLoading(true);
    setFormError('');
    try {
      const response = await fetch(`https://vtu9ja.ng/api/bill/bill-validation?meter_number=${meterNumber}&disco=${discoId}&meter_type=${meterType}`);
      const result = await response.json();
      if (result.status === 'success') {
        setValidationResponse(result.name);
        setIsValid(true);
      } else {
        setFormError('Validation failed');
        setIsValid(false);
      }
    } catch (error) {
      setFormError('Error validating meter number');
      setIsValid(false);
    } finally {
      setValidateMeterLoading(false);
      setTimeout(() => {
        setFormError('');
      }, 4000);
    }
  };

  const handlePlaceOrder = async () => {
    if (parseFloat(amount) > parseFloat(data.wallet)) {
      Swal.fire({ icon: 'error', title: 'Failed!', text: 'Amount exceeds wallet balance' });
      return;
    }

    if (transactionPin !== data.pin) {
      Swal.fire({ icon: 'error', title: 'Failed!', text: 'Invalid transaction pin' });
      return;
    }

    setPlaceOrderLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/Electric`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
        },
        body: JSON.stringify({
          domain: window.location.hostname,
          discoId,
          meterType,
          meterNumber,
          amount
        }),
      });

      const responseData = await response.json();

      if (response.ok) {
        Swal.fire({ icon: 'success', title: 'Success!', text: responseData.message }).then(() => {
          refreshUserInfo();
          formRef.current.reset();
        });
      } else {
        Swal.fire({ icon: 'error', title: 'Failed!', text: responseData.message || 'Failed to place order' });
      }
    } catch (error) {
      Swal.fire({ icon: 'error', title: 'Error!', text: `Error placing order: ${error.message}` });
    } finally {
      setPlaceOrderLoading(false);
    }
  };


  const refreshUserInfo = async () => {
    const token = localStorage.getItem('userToken');
    if (!token) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/userInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ token, domain: window.location.hostname }),
      });

      if (!response.ok) {
        throw new Error('Failed to refresh user info');
      }

      const responseData = await response.json();

      if (responseData.status === 'success') {
        setData(responseData.user);
      }

    } catch (error) {
      console.error('Error refreshing user info:', error);
    }
  };


  if (isLoading) return <Loading />;

  return (
    <div style={{ backgroundColor: '#F9FAFB', minHeight: '100vh' }}>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
            Back
          </Navbar.Brand>
          <Nav className="ml-auto">
            <Nav.Link>Buy Electricity</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Container className="mt-4 mb-5">
        <Row className="justify-content-center">
          <Col md={7}>
            <Card className="balance-card mb-4">
              <Card.Body className="d-flex align-items-center">
                <FaMoneyBillAlt className="menu-icon" />
                <div className="leftshift">
                  <h5>Wallet Balance</h5>
                  <div><b>₦{data?.wallet?.toLocaleString()}</b></div>
                </div>
              </Card.Body>
            </Card>

            <Card className="balance-card mb-4">
              <Card.Body>
                Buy Electricity {electricCharge !== null && `(Charge ₦${electricCharge})`}
                <Form ref={formRef}>
                  <Form.Group controlId="discoId">
                    <Form.Label>Disco ID</Form.Label>
                    <Form.Control as="select" value={discoId} onChange={(e) => setDiscoId(e.target.value)} required>
                      <option value="">Select Disco</option>
                      <option value="1">Ikeja Electricity</option>
                      <option value="2">Eko Electricity</option>
                      <option value="3">Kano Electricity</option>
                      <option value="4">Port Harcourt Electricity</option>
                      <option value="5">Joss Electricity</option>
                      <option value="6">Ibadan Electricity</option>
                      <option value="7">Kaduna Electric</option>
                      <option value="8">Abuja Electricity</option>
                      <option value="9">Enugu Electricity</option>
                      <option value="10">Benin Electricity</option>
                      <option value="11">Yola Electricity</option>
                      <option value="12">Aba Electricity</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="meterType">
                    <Form.Label>Meter Type</Form.Label>
                    <Form.Control as="select" value={meterType} onChange={(e) => setMeterType(e.target.value)} required>
                      <option value="">Select Meter Type</option>
                      <option value="prepaid">Prepaid</option>
                      <option value="postpaid">Postpaid</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="meterNumber">
                    <Form.Label>Meter Number</Form.Label>
                    <Form.Control type="text" value={meterNumber} onChange={(e) => setMeterNumber(e.target.value)} required />
                  </Form.Group>

                  <Form.Group controlId="amount">
                    <Form.Label>Amount</Form.Label>
                    <Form.Control
                      type="number"
                      value={amount}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (parseFloat(value) > parseFloat(data?.wallet)) {
                          setFormError('Amount exceeds wallet balance');
                          setTimeout(() => setFormError(''), 4000);
                        } else {
                          setFormError('');
                        }
                        setAmount(value);
                      }}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="transactionPin">
                    <Form.Label>Transaction Pin</Form.Label>
                    <Form.Control
                      type="password"
                      value={transactionPin}
                      onChange={(e) => setTransactionPin(e.target.value)}
                      required
                    />
                  </Form.Group>

                  {validateMeterLoading && <Spinner animation="border" variant="primary" />}
                  {formError && <div className="text-danger">{formError}</div>}
                  {validationResponse && !validateMeterLoading && (
                    <div className="mt-3">
                      <Form.Control type="text" value={validationResponse} readOnly />
                      <center>
                        <Button className="mt-2" variant="primary" onClick={handlePlaceOrder} disabled={placeOrderLoading || !isValid}>
                          {placeOrderLoading ? <Spinner animation="border" size="sm" /> : 'Place Order'}
                        </Button>
                      </center>
                    </div>
                  )}
                  {!validationResponse && !validateMeterLoading && (
                    <center>
                      <Button className="mt-2" variant="primary" onClick={handleValidateMeterNumber} disabled={validateMeterLoading}>
                        {validateMeterLoading ? <Spinner animation="border" size="sm" /> : 'Validate Meter Number'}
                      </Button>
                    </center>
                  )}
                </Form>
              </Card.Body>
            </Card>
            <Card className="balance-card mb-4">
              <Card.Body>
                Your token will be displayed upon successful completion. Additionally, previous tokens can be viewed on the order history page.
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default DataPage;